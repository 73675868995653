<template>
  <Sidebar :loading="!!loading"
           :instance="instance"
           @close="$router.push({name: 'team.index'})"
  >
    <template v-if="instance">
      <TeamLayout>
        <template v-slot:avatar>
          <AvatarEditable size="80" v-model="image" :subject="instance"></AvatarEditable>
        </template>
        <template v-slot:name>
          <v-text-field
              outlined dense flat placeholder="Name" class="text-h5 font-weight-bold" height="2em"
              :error-messages="getErrors('name')"
              :hide-details="!hasErrors('name')"
              @change="unsetError('name')"
              v-model="name"></v-text-field>
        </template>
        <template v-slot:role>
          <v-select
              class="font-weight-bold text-body-1 text-lowercase"
              v-model="role"
              filled dense flat
              placeholder="Select role"
              :items="[{value: 'admin', text: 'Administrator'}, {value: 'manager', text: 'Manager'}]"
              :error-messages="getErrors('role')"
              @change="unsetError('role')"
          ></v-select>
        </template>
        <template v-slot:login>
          <v-text-field id="login" background-color="white" class="transparent" filled dense flat
                        :hide-details="!hasErrors('login')"
                        :error-messages="getErrors('login')"
                        @change="unsetError('login')"
                        v-model="login"></v-text-field>
        </template>
        <template v-slot:password>
          <v-text-field id="password" background-color="white" class="transparent" filled dense flat
                        :hide-details="!hasErrors('password')"
                        :error-messages="getErrors('password')"
                        @change="unsetError('password')"
                        v-model="password"></v-text-field>
        </template>
      </TeamLayout>

      <v-spacer></v-spacer>

      <v-container fluid>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="secondary" :to="{name: 'team.index'}" plain>Cancel</v-btn>
          <v-btn large color="success" :loading="!!loading" @click="save()" depressed>Save</v-btn>
        </v-card-actions>
      </v-container>

    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import {mapActions, mapGetters, mapState} from "vuex";
import Avatar from "../../components/common/Avatar";
import {mapFields} from "vuex-map-fields";
import AvatarEditable from "../../components/common/AvatarEditable";
import TeamLayout from "../../components/layout/TeamLayout";

export default {
  name: "TeamEditor",
  components: {TeamLayout, AvatarEditable, Sidebar},
  props: [
    'uuid'
  ],
  computed: {
    ...mapState('team/instance', {
      instance: 'instance',
      loading: 'loading',
    }),
    ...mapFields('team/instance', {
      name: 'instance.name',
      role: 'instance.role',
      login: 'instance.login',
      password: 'instance.password',
      image: 'instance.image'
    }),
    ...mapGetters('error', {
      hasErrors: 'hasErrors',
      getErrors: 'getErrors',
    }),
  },
  methods: {
    ...mapActions('team/instance', {
      save: 'save',
      cancel: 'cancel',
    }),
    ...mapActions('error', {
      unsetError: 'unset',
      clearErrors: 'clear',
    }),
  },

  beforeDestroy: function () {
    this.clearErrors();
  },
}
</script>