<template>
  <v-input hide-details>
    <v-list-item class="px-0 d-flex align-content-end">
      <v-list-item-action class="mr-2">
        <v-avatar
            class="profile"
            color="grey"
            :size="size"
            v-bind="layout"
        >
          <v-img :width="size" :height="size" @error="onImgError" :lazy-src="getFallbackUrl()" :src="imageUrl"></v-img>
        </v-avatar>
      </v-list-item-action>
      <v-list-item-action class="mt-auto">
        <v-btn @click="$refs.upload.$refs.input.click()" large class="info--text light ml-3" depressed>Change</v-btn>
      </v-list-item-action>
      <v-spacer></v-spacer>
    </v-list-item>
    <v-file-input
        style="display: none"
        accept="image/png, image/jpeg, image/gif"
        prepend-icon=""
        hide-input
        dense
        solo flat
        ref="upload"
        :value="value"
        @input="v => $emit('input', v)"
        @change="(file) => previewImage(file)"
    >
    </v-file-input>
  </v-input>
</template>

<script>
import Avatar from "./Avatar";
import {get} from "lodash";

export default {
  props: {
    subject: {
      default: null,
    },
    layout: {
      default: null,
    },
    value: {
      default: null,
    },
    size: {
      default: 72,
    },
  },
  name: "AvatarEditable",
  data: () => ({
    url: null,
    failed: false,
  }),
  computed: {
    imageUrl() {
      if (this.url) return this.url;
      if (this.failed) return this.getFallbackUrl();
      let src = get(this.$props.subject, 'avatar.src', null);
      let nocache = `?v=${(new Date()).getTime()}`;
      if (src) return this.getUrl(src) + nocache;
      else return this.getFallbackUrl();
    }
  },
  methods: {
    previewImage(file) {
      if (file) {
        this.url = URL.createObjectURL(file);
      } else {
        this.url = null;
      }
      this.$emit('input', file)
    },
    getUrl: function () {
      return this.url || get(this.$props.subject, 'avatar.src', null);
    },
    getFallbackUrl() {
      return require('../../../public/icons/avatar-00.png')
    },
    onImgError: function () {
      this.failed = true;
    },
  }
}
</script>
